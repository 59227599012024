import Card from 'react-bootstrap/Card';
import {useEffect, useState} from "react";
import './Home.css'

function Contact() {
    

    return (
      <section id="contact" className="m-5">
      <div class="container">
      <div className='m-3 fs-1 td fw-bold'>Contact
      <h2 className='text-center'>----------o----------</h2>
      </div>
          {/* <p class="text-center w-75 m-auto">Haben Sie Fragen zu unseren Reisen oder möchten Sie buchen? Wir sind persönlich für Sie da, um zu beraten! Schreiben Sie gerne eine Email oder rufen Sie uns an!</p> */}
          <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-3 my-5">
              <div class=" border-0">
                 <div class=" text-center">
                   <h4 class="td mb-2">Registration Office</h4>
                   <p>Flat No.: 749,Pocket -C, D.D.A. Flats, Loknayak Puram
<br></br> Bakkarwala, New Delhi – 110041
India</p>
                 </div>
               </div>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-3 my-5">
              <div class=" border-0">
                 <div class=" text-center">
                   <h4 class="td mb-2">Work Place</h4>
                  <p>4 Mansil Haveli, Village Barua Husainpur, Post Office Bhuta <br></br> 
Tehsil Faridpur, Bareilly – 243123
</p>
                 </div>
               </div>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-3 my-5">
              <div class=" border-0">
                 <div class=" text-center">
                   <h4 class="td mb-2">Contact Number</h4>
                   <p>+91 94585 88545</p>
                 </div>
               </div>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-3 my-5">
              <div class=" border-0">
                 <div class=" text-center">
                   <h4 class="td mb-2">Email</h4>
                   <p className="text-dark">info@handinhandhelp.org</p>
                   
                 </div>
               </div>
            </div>
          </div>
      </div>
   </section>
    );
  }
  
  export default Contact;