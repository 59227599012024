import Card from 'react-bootstrap/Card';
import {useEffect, useState} from "react";
import './Home.css'
import { collection } from "firebase/firestore";
import {getDocs} from "firebase/firestore";
import { db } from "./firebase-config";
import Carousel from 'react-bootstrap/Carousel';

function About() {
  const [gallerydata, setgallerydata] = useState([]);
  const [aboutdata, setaboutdata] = useState([]);

  const packagedata3 = async () => {
    const colRef = collection(db, "Guids");
    const snapshots = await getDocs(colRef);
    const docs = snapshots.docs.map(doc => doc.data());
        setaboutdata(docs);
  }

  const packagedata2 = async () => {
    const colRef = collection(db, "sustg");
          const snapshots = await getDocs(colRef);
          const docs = snapshots.docs.map(doc => doc.data());
          setgallerydata(docs)
  }

  useEffect(() => {
    packagedata3();
    packagedata2();
}, []);
    

    return (
       <div>
        <div className=' w-[80%] mx-auto my-5'>
      <Card.Header className='m-3 fs-1 td fw-bold'>About Us
      <h2 className='text-center'>----------o----------</h2>
      </Card.Header>
      <Card.Body>
      <div>
      <div className='mt-1'>
                <Carousel>
                {
                                    gallerydata.map(ele => (
                                        <Carousel.Item>
                                            <img className="d-block"
                                                src={
                                                    ele.image
                                                }
                                                alt="First slide"
                                                style={{"height":"725px"}}
                                                />
                                            <Carousel.Caption>
                                                <h3>{
                                                    ele.title
                                                }</h3>
                                                <p className='fw-bold shadow-sm '>{ele.subtitle}</p>
                                            </Carousel.Caption>
                                        </Carousel.Item>
                                    ))
                                }
                </Carousel>
            </div>
             <div class=" my-3" >
  {aboutdata.map(Guidsele => ( 
        <p class="card-text"> {
                                        <div dangerouslySetInnerHTML={{ __html:Guidsele.name}} />
                                    
                                }</p>
      ))}
</div>
             </div>
      </Card.Body>
    </div>
       </div>
    );
  }
  
  export default About;