import Card from 'react-bootstrap/Card';
import {useEffect, useState} from "react";
import { collection } from "firebase/firestore";
import {getDocs} from "firebase/firestore";
import { db } from "../firebase-config";

function Farmer() {
    const [aboutdata, setaboutdata] = useState([]);

    const fidata3 = async () => {
      const colRef = collection(db, "chapters");
      const snapshots = await getDocs(colRef);
      const docs = snapshots.docs.map(doc => doc.data());
          setaboutdata(docs);
    }
  
    useEffect(() => {
      fidata3();
  }, []);
    

    return (
       <div>
        <Card className='m-5'>
      <Card.Header className='m-3 fs-1 td fw-bold'>Farmer
      <h2 className='text-center'>----------o----------</h2>
      </Card.Header>
      <Card.Body>
      <div>
             

<div class="relative overflow-x-auto">
    <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
        <thead class=" text-gray-900 fw-bold uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
            <th scope="col" class="px-6 py-3 ">
                    Title
                </th>
                {/* <th scope="col" class="px-6 py-3">
                    Color
                </th>
                <th scope="col" class="px-6 py-3">
                    Category
                </th> */}
                <th scope="col" class="px-6 py-3">
                    Open
                </th>
            </tr>
        </thead>
        <tbody>
        {
    aboutdata.filter((e)=>((e.academics==="farmer"))).map(board=>(
        <tr class="bg-white dark:bg-gray-800">
        <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
            {board.name}
        </th>
        {/* <td class="px-6 py-4">
            Black
        </td>
        <td class="px-6 py-4">
            Accessories
        </td> */}
        <td class="px-6 py-4">
            <a href={board.link} className='font-bold ' style={{"textDecoration":"none"}}>Open</a>
        </td>
    </tr>
    ))
  }
        </tbody>
    </table>
</div>

             </div>
      </Card.Body>
    </Card>
       </div>
    );
  }
  
  export default Farmer;