import React from "react";
import {useState,useEffect} from "react";
import {Link, Router, useNavigate} from 'react-router-dom'
import {auth} from '../firebase-config';
import {db} from '../firebase-config'
import {setDoc, doc} from 'firebase/firestore'
import {ref, uploadBytes, getDownloadURL} from "firebase/storage"
import {storage} from "../firebase-config";
import { onAuthStateChanged} from "firebase/auth";
import { collection } from "firebase/firestore";
import {getDoc} from "firebase/firestore";
import Card from 'react-bootstrap/Card';
import {useLocation} from "react-router-dom";

function Regster() {
    const location = useLocation();
    const navigate = useNavigate();
    const [user, setuser] = useState();
    const [gallerydata, setgallerydata] = useState([]);
    const [d1,setd1] = useState("");
    const [d2,setd2] = useState("");
    const [d3,setd3] = useState("");
    const [d4,setd4] = useState("");
    const [d5,setd5] = useState("");
    const [d6,setd6] = useState("");
    const [d7,setd7] = useState(0);
    const [d8,setd8] = useState("");
    const [d9,setd9] = useState("");
    const [d10,setd10] = useState("");
    const [d11,setd11] = useState("");
    const [d13,setd13] = useState(false);

    
    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                packagedata3();
                setuser(user);
            } else {
            }
        });
    }, [auth.currentUser]);
    

    const packagedata3 = async () => {
        const docRef = doc(db, "Donation",location.search.slice(1,100));
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
              setgallerydata(docSnap.data())
              console.log(docSnap.data(),"ji")
              } else {
              }
      }
      const handleSubmit1 = (event) => {
        event.preventDefault();
        
             setDoc(doc(db, "Donationbank",gallerydata.id), {
                id:gallerydata.id,
             uid:user.uid,
             bank:d1,
            add:d2,
             tid:d3,
             date:d4,
             });
             setd13(true)
      };

      const handleSubmit2 = (event) => {
        event.preventDefault();
        
             setDoc(doc(db, "Donationcheque",gallerydata.id), {
                id:gallerydata.id,
             uid:user.uid,
             name:d5,
           acno:d6,
             amount:d7,
             date:d8,
             chequeno:d9,
             ifc:d10,
             bankadd:d11,
             });
             setd13(true)
      };
    
    
    
  
////////////////////////////////////////////////////////////////////////////////////////


    return (
        <div className="m-5">
             <div className='w-[80%] mx-auto my-5'>
      <div className='m-3 fs-1 td fw-bold'>
      More Information
        <h2 className='text-center'>----------o----------</h2>
        </div>
      <div>
       <div>
        {
            d13===false ? <div>{
                gallerydata.mode==="Bank Transfer"?
                <div>
                <div class="grid gap-6 mb-6 md:grid-cols-2">
                     <div>
                    <label for="company" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-start ml-2">Name of your Bank</label>
                    <input type="text" id="company" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required onChange={
                                (e) => {
                                    setd1(e.target.value);
                                }
                            }/>
                </div> 
                <div>
                    <label for="company" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-start ml-2">Address of bank</label>
                    <input type="text" id="company" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required onChange={
                                (e) => {
                                    setd2(e.target.value);
                                }
                            }/>
                </div> 
                <div>
                    <label for="company" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-start ml-2">Transection ID </label>
                    <input type="text" id="company" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required onChange={
                                (e) => {
                                    setd3(e.target.value);
                                }
                            }/>
                </div> 
                <div>
                    <label for="company" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-start ml-2">Date of Money Transfer</label>
                    <input type="date" id="company" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required onChange={
                                (e) => {
                                    setd4(e.target.value);
                                }
                            }/>
                </div>
                <div class="flex items-start mb-6">
        <input id="default-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
        <label for="default-checkbox" class="text-sm font-medium text-black dark:text-gray-300 text-start ml-1"> I hereby declare that I am a citizen of India, making this donation out of my own funds. The information provided above is correct to the best of my knowledge. I know that all further communications will be done on contact details provided above.
    </label>
    </div>
                <div>
                <h5 className="text-start py-2">Name of Bank Account: Hand In Hand Trust</h5>
           <h5 className="text-start py-2">Account Number: 419001010050821 <br></br>
           IFSC Code: UBIN0541907
            </h5>
            <h5 className="text-start py-2">Bank Address: Mall Road Branch, New Delhi - 110009 </h5>
                    </div>
                </div> 
                <button type="submit" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" onClick={(e)=>{
                handleSubmit1(e)
            }}>Submit
            </button>
            </div>
                 :""
            }
            {
                gallerydata.mode==="Cheque"? 
                <div>
                <div class="grid gap-6 mb-6 md:grid-cols-2">
                <div>
               <label for="company" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-start ml-2">Name of Bank on Cheque: </label>
               <input type="text" id="company" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required onChange={
                           (e) => {
                               setd5(e.target.value);
                           }
                       }/>
           </div> 
           <div>
               <label for="company" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-start ml-2">Account Number on Cheque:</label>
               <input type="text" id="company" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required onChange={
                           (e) => {
                               setd6(e.target.value);
                           }
                       }/>
           </div> 
           <div>
               <label for="company" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-start ml-2">Amount on Cheque: </label>
               <input type="Number" id="company" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required onChange={
                           (e) => {
                               setd7(e.target.value);
                           }
                       }/>
           </div> 
           <div>
               <label for="company" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-start ml-2">Date on Cheque:</label>
               <input type="date" id="company" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required onChange={
                           (e) => {
                               setd8(e.target.value);
                           }
                       }/>
           </div> 
           <div>
               <label for="company" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-start ml-2">Cheque Number:</label>
               <input type="text" id="company" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required onChange={
                           (e) => {
                               setd9(e.target.value);
                           }
                       }/>
           </div>
           <div>
               <label for="company" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-start ml-2">IFSC Code on Cheque: </label>
               <input type="text" id="company" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required onChange={
                           (e) => {
                               setd10(e.target.value);
                           }
                       }/>
           </div>
           <div>
               <label for="company" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-start ml-2">Bank Address on Cheque</label>
               <input type="text" id="company" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required onChange={
                           (e) => {
                               setd11(e.target.value);
                           }
                       }/>
           </div>
           <div class="flex items-start mb-6">
        <input id="default-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
        <label for="default-checkbox" class="text-sm font-medium text-black dark:text-gray-300 text-start ml-1"> I hereby declare that I am a citizen of India, making this donation out of my own funds. The information provided above is correct to the best of my knowledge. I know that all further communications will be done on contact details provided above.
    </label>
    </div>
           </div>
           <button type="submit" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" onClick={(e)=>{
                handleSubmit2(e)
            }}>Submit
            </button>
            </div>
             :""
            }</div> :<div>
            <h5 className="text-start">Dear Friend,</h5>
            <h5 className="text-start py-2">On behalf of Team Hand in Hand Trust, I extend sincere thanks for your generous donation to our noble cause. Your contribution is deeply appreciated and will make a significant impact on our efforts to support those in need.</h5>
            <h5 className="text-start py-2">We have received your details and are currently processing them. Upon confirmation, you will receive your donation certificate as a token of our appreciation.
Once again, thank you for your kindness and support. Your generosity brings joy and hope to those we serve.
</h5>
           <h5 className="text-start py-2">Warm regards,</h5>
           <h5 className="text-start py-2">Team <br></br>
            Hand in Hand Trust
            </h5>
           </div>
        }
        
        {
            gallerydata.mode==="Cash"? <div>
            <h5 className="text-start">Dear Friend,</h5>
            <h5 className="text-start py-2">On behalf of Team Hand in Hand Trust, I extend sincere thanks for your generous donation to our noble cause. Your contribution is deeply appreciated and will make a significant impact on our efforts to support those in need.</h5>
            <h5 className="text-start py-2">We have received your details and are currently processing them. Upon confirmation, you will receive your donation certificate as a token of our appreciation.
Once again, thank you for your kindness and support. Your generosity brings joy and hope to those we serve.
</h5>
           <h5 className="text-start py-2">Warm regards,</h5>
           <h5 className="text-start py-2">Team <br></br>
            Hand in Hand Trust
            </h5>
           </div>:""
        }
        </div>
</div>
    </div>
</div>
    );
  }
  
  export default Regster;