import React ,{ useRef }from 'react';
import html2canvas from 'html2canvas';
import {useState,useEffect} from "react";
import {db} from '../firebase-config'
import {doc,getDoc} from 'firebase/firestore'
import {useLocation} from "react-router-dom";
import pic from "../../Images/profile.jpg"
import Image from 'react-bootstrap/Image'
import logo from '../../Images/logo.png'
const VolunteerIDCard = () => {
  const location = useLocation();
  const [vdata,setvdata] = useState([]);

    

    const handleDownload = () => {
      window.print();
    };

    useEffect(() => {
     packagedata3()
  }, []);


    const packagedata3 = async () => {
      const docRef = doc(db, "Volunteer",location.state.id);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
              setvdata(docSnap.data())
              console.log(docSnap.data())
            } else {
            }
    }


  return (
    <div>
         <button onClick={() => handleDownload()} className="block mx-5 my-8 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Print</button>
    <div className="w-50 flex flex-row justify-between mx-auto bg-white shadow-md  overflow-hidden  m-5" role="group">
      <div className="px-1 py-1 bg-blue-700 rounded-r-lg w-25">
      <img
            className="object-fill rounded-lg w-72 h-32  group-hover:opacity-70"
            src={vdata.stuimage || pic}
            alt="Profile"
          />
        <p className="text-gray-200 text-sm font-bold mt-1">{vdata.fname} {vdata.mname} {vdata.lname}</p>
      </div>
      <div className="py-1 px-1">
      <Image className="navimage"
                        src={logo}
                        alt="First slide"></Image>
        <p className="text-gray-800 text-base">
          <span className="font-semibold">User Id:</span> {vdata.id1}
        </p>
        <p className="text-gray-600 text-sm">Validity: {vdata.from} To {vdata.till}</p>
      </div>
      <div className="bg-blue-700 px-1 py-1 rounded-l-lg w-25">
        <p className="text-base text-white font-bold ">
         Event
         <br></br>
         {vdata.ename}
        </p>
      </div>
    </div>
    </div>
  );
};

export default VolunteerIDCard;
