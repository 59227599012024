import React from "react";
import {useState,useEffect} from "react";
import {Link, Router, useNavigate} from 'react-router-dom'
import {auth} from '../firebase-config';
import {db} from '../firebase-config'
import {setDoc, doc} from 'firebase/firestore'
import {ref, uploadBytes, getDownloadURL} from "firebase/storage"
import {storage} from "../firebase-config";
import { onAuthStateChanged} from "firebase/auth";
import { collection } from "firebase/firestore";
import {getDocs} from "firebase/firestore";
import Card from 'react-bootstrap/Card';

function Regster() {
    const [user, setuser] = useState();
    const nav = useNavigate();
    const [condition1, setcondition1] = useState(false);
    const [condition, setcondition] = useState(false);
    const [d1, setd1] = useState(""); 
    const [d3, setd3] = useState(""); 
    const [d4, setd4] = useState(0); 
    const [d13, setd13] = useState(""); 
    const [d14, setd14] = useState(""); 
    const [d15, setd15] = useState(""); 
    const [d16, setd16] = useState("");  
    const [url, seturl] = useState(""); 
    const [d17, setd17] = useState(0);  
    const [d6, setd6] = useState(""); 
    const [d9, setd9] = useState(""); 
    const [d10, setd10] = useState(""); 
    const [d11, setd11] = useState(1); 
    const [d12, setd12] = useState("Indian"); 
    const [d5, setd5] = useState(""); 
    const [d18,setd18] = useState('');
    const [gallerydata, setgallerydata] = useState([]);
    const id=Math.random().toString();
    

    
    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                setuser(user);
            } else {
                setuser([]);
            }
        });
        packagedata3();
    }, [auth.currentUser]);
    

    const packagedata3 = async () => {
        const colRef = collection(db, "Events");
        const snapshots = await getDocs(colRef);
        const docs = snapshots.docs.map(doc => doc.data());
            setgallerydata(docs);
      }

    
  const handleSubmit = (event) => {
    event.preventDefault();
    if(!d1,!d3,!d4,!d10,!d11,!d12,!d9,!d12,!d13,!d14,!d15,!d16,!d17,!d18,!d5,!d6,!d9){
        alert("please fill all details")
        return;
     }
     if(!condition || !condition1){
        alert("please check conditions")
        return;
     }
         setDoc(doc(db, "Donation",id), {
            id:id,
         uid:user.uid,
         name:d1,
        pan:url,
         address:d3,
         mobile:d4,
         dist:d14,
         tah:d13,
         state:d15,
         country:d16,
         pin:d17,
         amount:d11,
         dob:d6,
         Purpose:d10,
         details:d12,
         mode:d5,
         emaol:d9,
         town:d18
         });
         alert("Congrates you have registered");
  };

  ///////////////////////////////////////////////////////////////////////////////////

  const loadScript = (src) => {
    return new Promise( (resovle) => {
    const script = document. createElement('script');
    script.src = src;
    script.onload = () => {
    resovle(true)
    }
    script.onerror = () => {
    resovle (false)
    }
    document.body.appendChild(script)
    });
}
  
const pay = async (e)=>{
    e.preventDefault();
    const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js")
    if (!res) {
       alert('You are offline... Failed to load Razorpay SDK');
       return;
    }
   
    const options = {
       key:"rzp_live_b56dQK6VWnXnHB",
       currency:"INR",
       amount:d11*100,
       name: "Hand In Hand Help | NGO", 
       description: "Ordering 1 thumbnail", 
       image: 'xyz',

       handler: function (response) {
       alert ("Payment Successfully");
       
       // alert ("payment id: " + response.razorpay_payment_id)
       }, 
       prefill: {
       name:
       "Hand In Hand Help | NGO"
       }
       };

       const paymentObject = new window.Razorpay(options);
       paymentObject.open()
}


////////////////////////////////////////////////////////////////////////////////////////


    return (
        <div className="m-5">
             <div className='w-[80%] mx-auto my-5'>
      <div className='m-3 fs-1 td fw-bold'>
      Donation's Registration
        <h2 className='text-center'>----------o----------</h2>
        </div>
      <div>
        <div>
        <div>
            <label for="first_name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-start ml-2">Citizenship</label>
 <select id="countries" class="mb-6 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
onChange={(e)=>{
    setd12(e.target.value)
}}
>
  <option selected>Choose...</option>
  <option value="Indian" >Indian</option>
  <option value="Non-Indian">Non-Indian</option>
</select>
        </div>
       {
        d12==="Indian" ? <form onSubmit={handleSubmit}>
        <div class="grid gap-6 mb-6 md:grid-cols-2">
        <div>
                <label for="first_name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-start ml-2">Purpose of Donation</label>
                <select id="countries" class="mb-6 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
    onChange={(e)=>{
        setd10(e.target.value);
    }}
    >
      <option selected>Choose...</option>
      {gallerydata.filter((e)=>(new Date(e.date).getTime()>Date.now())).slice(0,3).map(packageele => ( 
     <option value={packageele.name}><div dangerouslySetInnerHTML={{ __html:packageele.name}} /></option>
      ))}
    </select>
            </div>
            <div>
                <label for="first_name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-start ml-2">Amount of Donation</label>
                <input type="Number" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required onChange={
                            (e) => {
                                setd11(e.target.value);
                            }
                        }/>
            </div>
            <div>
                <label for="visitors" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-start ml-2">Pay Via</label>
                <select id="countries" class="mb-6 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
    onChange={(e)=>{
        setd5(e.target.value)
    }}
    >
      <option selected>Choose...</option>
      <option value="Razorpay">Razor pay</option>
      <option value="Bank Transfer">Bank Transfer</option>
      <option value="Cheque">Cheque</option>
      <option value="Cash">Cash</option>
    </select>
            </div>
            <div>
                <label for="first_name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-start ml-2">Full name (as in Aadhar/Government Id)</label>
                <input type="text" id="first_name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required onChange={
                            (e) => {
                                setd1(e.target.value);
                            }
                        }/>
            </div>
            <div>
                <label for="company" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-start ml-2">Address (Flat number, Street)</label>
                <input type="text" id="company" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required onChange={
                            (e) => {
                                setd3(e.target.value);
                            }
                        }/>
            </div> 
            <div>
                <label for="company" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-start ml-2">Town/Village</label>
                <input type="text" id="company" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required onChange={
                            (e) => {
                                setd18(e.target.value);
                            }
                        }/>
            </div> 
            <div>
                <label for="company" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-start ml-2">Tehsil</label>
                <input type="text" id="company" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required onChange={
                            (e) => {
                                setd13(e.target.value);
                            }
                        }/>
            </div>  
            <div>
                <label for="company" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-start ml-2"> District</label>
                <input type="text" id="company" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required onChange={
                            (e) => {
                                setd14(e.target.value);
                            }
                        }/>
            </div>  
            <div>
                <label for="company" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-start ml-2">State</label>
                <input type="text" id="company" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required onChange={
                            (e) => {
                                setd15(e.target.value);
                            }
                        }/>
            </div>  
            <div>
                <label for="company" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-start ml-2">Pin Code</label>
                <input type="Number" id="company" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required onChange={
                            (e) => {
                                setd17(e.target.value);
                            }
                        }/>
            </div> 
            <div>
                <label for="company" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-start ml-2">Country</label>
                <input type="text" id="company" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required onChange={
                            (e) => {
                                setd16(e.target.value);
                            }
                        }/>
            </div>
            <div>
                <label for="company" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-start ml-2">Pancard no</label>
                <input type="text" id="company" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required onChange={
                            (e) => {
                                seturl(e.target.value);
                            }
                        }/>
            </div>
            <div>
                <label for="company" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-start ml-2">Email</label>
                <input type="text" id="company" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required onChange={
                            (e) => {
                                setd9(e.target.value);
                            }
                        }/>
            </div>
            <div className="mb-6 ">
            <label for="company" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-start ml-2">Date Of Birth: (DD/MM/YYYY)</label>
                  <input type="date" value={d6} class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"  onChange={(e)=>{
                    setd6(e.target.value)
                  }}/>
          </div>   
            <div class="mb-6">
            <label for="password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-start ml-2">Mobile Number (If Possible : whatsapp)
    </label>
            <input type="Number" id="password" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required onChange={
                            (e) => {
                                setd4(e.target.value);
                            }
                        }/>
        </div>
        </div>
        <h6 className='text-start text-sm  text-black ml-3 mb-6'>** Information is being collected to comply with government regulations and shall be treated as confidential. These details shall not be divulged for any other purpose. By sharing your details, you agree to receive stories and updates from Hand in Hand Trust via mobile, Whatsapp, email and post. 
    </h6>  
    <div class="flex items-start mb-6">
        <input id="default-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" onChange={(e)=>{
            setcondition(e.target.checked)
        }}/>
        <label for="default-checkbox" class="text-sm font-medium text-black dark:text-gray-300 text-start ml-1"> I hereby declare that I am a citizen of India, making this donation out of my own funds. The information provided above is correct to the best of my knowledge. I know that all further communications will be done on contact details provided above.
    </label>
    </div>
    
    <div class="flex items-start mb-6">
        <input id="default-checkbox" type="checkbox" value="" class="w-3 h-3 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" onChange={(e)=>{
           setcondition1(e.target.checked)
        }}/>
        <label for="default-checkbox" class="text-sm  font-medium text-black dark:text-gray-300 ml-1"> <a href="/conditions" style={{"textDecoration":"none"}} className="text-black">T&C for Return and Refund</a>
    </label>
    </div>
    {
       d5==="Razorpay" ? <button type="submit" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" onClick={(e)=>{
        handleSubmit(e)
        pay(e);
    }}>Continue to Payment
    </button>: <button type="submit" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" onClick={(e)=>{
            handleSubmit(e)
            nav({
                pathname:"/donation2",
                search:id
              })
        }}>Continue to Payment
        </button>
    }
    </form> :
     <div>
     <h5 className="text-start">Dear Esteemed Friend,</h5>
     <h5 className="text-start py-2">It is with profound gratitude that we acknowledge your esteemed interest in contributing to the noble cause supported by Hand in Hand Trust. Your compassionate gesture signifies a commitment to fostering positive change in society, for which we are sincerely appreciative.</h5>
     <h5 className="text-start py-2">We are pleased to inform you that we have received authorization from the Government of India to accept donations from international benefactors such as yourself. However, to facilitate this process seamlessly, it is imperative for us to establish a new bank account at the esteemed Main Branch of the State Bank of India in New Delhi. As with any regulatory procedure, this undertaking may necessitate some time for completion. Nevertheless, please be assured that we are diligently working to expedite this process.</h5>
     <h5 className="text-start py-2">In the interim, we kindly invite you to communicate the amount of your generous contribution through either our WhatsApp number at +919458588545 or via email at info@handinhandhelp.org. Your proactive engagement in this matter will enable us to keep you promptly informed once the new account is operational.</h5>
    <h5 className="text-start py-2">With heartfelt gratitude and warm regards,</h5>
    <h5 className="text-start py-2">Team <br></br>
     Hand in Hand Trust
     </h5>
    </div>
       }
</div>
</div>
    </div>
</div>
    );
  }
  
  export default Regster;