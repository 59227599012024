import Card from 'react-bootstrap/Card';

function Conditions() {
   

    return (
       <div>
        <Card className='m-5'>
      <Card.Header className='m-3 fs-1'>Terms & Conditions</Card.Header>
      <h3 className='text-center'>----------o----------</h3>
      <Card.Body>
        <p>1. Payment Processing: Hand in Hand Trust provides a secure payment gateway for processing transactions related to our services. By utilizing our payment gateway, you agree to abide by the following terms and conditions.
        </p>
        <p>2. Non-Refundable Transactions: All transactions processed through the Hand in Hand Trust payment gateway are deemed final and non-refundable. Once a transaction is completed, it cannot be reversed, refunded, or returned under any circumstances.</p>
        <p>3. Confirmation of Payment: Upon successful completion of a transaction, you will receive confirmation of payment via the provided contact information or through the designated communication channel.</p>
        <p>4. Transaction Disputes: In the event of a dispute regarding a transaction, including but not limited to unauthorized charges or billing errors, you must contact Hand in Hand Trust's customer support promptly for resolution. However, it is important to note that such disputes do not guarantee a refund or reversal of the transaction.</p>
        <p>5. Security Measures: Hand in Hand Trust employs industry-standard security measures to safeguard your payment information and ensure secure transactions. However, you acknowledge that no method of transmission over the internet or electronic storage is 100% secure, and Hand in Hand Trust shall not be liable for any breaches of security beyond our reasonable control.</p>
        <p>6. Authorized Access: You are solely responsible for maintaining the confidentiality of your account credentials and ensuring that only authorized individuals access your account to initiate transactions through our payment gateway. Hand in Hand Trust shall not be held liable for any unauthorized use of your account.</p>
        <p>7. Availability of Services: Hand in Hand Trust endeavors to maintain uninterrupted access to our payment gateway services. However, we do not guarantee continuous, uninterrupted, or secure access to our services, and we reserve the right to suspend, withdraw, or restrict access to the payment gateway at any time without prior notice.</p>
        <p>8. Acceptance of Terms: By using Hand in Hand Trust's payment gateway, you acknowledge that you have read, understood, and agreed to these terms and conditions. If you do not agree with any provision of these terms, you must refrain from using our payment gateway.</p>
        <p>9. Modifications: Hand in Hand Trust reserves the right to modify, update, or revise these terms and conditions at any time without prior notice. It is your responsibility to regularly review these terms for any changes. Your continued use of our payment gateway after any modifications constitutes acceptance of the revised terms.</p>
        <p>10. Governing Law: These terms and conditions shall be governed by and construed in accordance with the laws of [Jurisdiction], without regard to its conflict of law principles.
If you have any questions or concerns regarding these terms and conditions, please contact Hand in Hand Trust's customer support for assistance. Our email: <a href='mailto:info@handinhandhelp.org'>info@handinhandhelp.org</a> & WhatsApp No.: +919458588545.</p>
      </Card.Body>
    </Card>
       </div>
    );
  }
  
  export default Conditions;