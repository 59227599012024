import Card from 'react-bootstrap/Card';
import {useEffect, useState} from "react";
import './Home.css'
import { collection } from "firebase/firestore";
import {getDocs} from "firebase/firestore";
import { db } from "./firebase-config";

function Member() {
  const [Team1, setTeam1] = useState([]);


  const Teamdata1 = async () => {
    const colRef = collection(db, "Team");
    const snapshots = await getDocs(colRef);
    const docs = snapshots.docs.map(doc => doc.data());
        setTeam1(docs);
  }


  useEffect(() => {
    Teamdata1();
}, []);




    return (
      <div className="m-5">
     <div className='m-3 fs-1 td fw-bold'>Team
      <h2 className='text-center'>----------o----------</h2>
      </div>
      <div>
      {Team1.map(Teamele => ( 
             <div class="card m-5">
             <div class="row g-0">
                 <div class="col-md-4">
                     <img src={
                             Teamele.image
                         }
                         class="img-fluid rounded-start"
                         alt="..."/>
                 </div>
                 <div class="col-md-8">
                     <div class="card-body">
                         <h5 class="card-title">
                             {
                              <div dangerouslySetInnerHTML={{ __html:Teamele.name}} />
                            
                         }</h5>
                         <p class="card-text text-start">
                             {
                              <div dangerouslySetInnerHTML={{ __html:Teamele.subtitle}} />
                             
                         }</p>
                     </div>
                 </div>
             </div>
         </div>
               ))}
      </div>
  </div>
    );
  }
  
  export default Member;