import React from 'react';
import {useLocation} from "react-router-dom";
import {useEffect, useState} from "react";
import {useNavigate} from 'react-router-dom'
import {collection} from "firebase/firestore";
import {getDocs} from "firebase/firestore";
import Carousel from 'react-bootstrap/Carousel';
import {db} from '../firebase-config'
import '../Home.css';


const Package = () => {
    const location = useLocation();
    const [Package, setpackage] = useState([]);
    const [Package1, setpackage1] = useState([]);
    


    const packagedata3 = async () => {
        const colRef = collection(db, "Events");
        const snapshots = await getDocs(colRef);
        const docs = snapshots.docs.map(doc => doc.data());
            setpackage(docs);
      }

      const packagedata4 = async () => {
        const colRef = collection(db, "eventamount");
        const snapshots = await getDocs(colRef);
        const docs = snapshots.docs.map(doc => doc.data());
            setpackage1(docs);
      }
    
      useEffect(() => {
        packagedata3();
        packagedata4();
    }, []);



    return (
        <div>
            <div> {
                Package.filter(e => e.id == location.state.id).map(packageele => (
                    <div>
                        <div class="   w-[80%] mx-auto">
                        <Carousel className=''>
                                    {
                                    packageele.images.map(ele => (
                                        <Carousel.Item>
                                            <img className="d-block"
                                                src={
                                                    ele.url
                                                }
                                                alt="First slide"
                                                style={{"height":"725px"}}
                                                />
                                            <Carousel.Caption>
                                                
                                            </Carousel.Caption>
                                        </Carousel.Item>
                                    ))
                                } </Carousel>
                                {
                Package1.filter(e => e.id == location.state.id).map(packageele => (
                                <div class="table-responsive ">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                            <th scope="col">
                                                <p className='text-white p-1 ghhj text-base mx-5'>Amount Needed : {packageele.amount1}</p>
                                                </th>
                                                <th scope="col ">
                                                    <p className='text-white p-1 ghhj text-base mx-5'>Amount Raised : {packageele.amount2}</p>
                                                </th>
                                            {
                                    location.state.past ?"":<th scope="col">
                                    <a href="/donationreg"
                                    style={{textDecoration:"none"}}
                                    ><h2 className='text-white p-1 ghhj ml-5'>Donate</h2></a>
                                </th>
                                }
                                            </tr>
                                        </thead>
                                    </table>
                                </div>
                ))}
                            <div class="card-body">
                                <h5 class="card-title text-start fg fs-2">
                                <div dangerouslySetInnerHTML={{ __html:packageele.name}} /></h5>
                                <blockquote class="blockquote mb-0">
                                    <footer class="blockquote-footer text-start">
                                        <div className='text-start'
                                            dangerouslySetInnerHTML={
                                                {__html: packageele.subtitle}
                                            }/>
                                    </footer>
                                </blockquote>
                               
        </div>
    </div>
</div>
                ))
            } </div>
        </div>
    );
}

export default Package;