import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";
import {getAuth} from "firebase/auth";
import {getFirestore} from "firebase/firestore";
import {getStorage} from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyDgwGmcFwGrGMx6Tyzj_GEFKcD0k2bsw_0",
  authDomain: "hands-in-hand.firebaseapp.com",
  projectId: "hands-in-hand",
  storageBucket: "hands-in-hand.appspot.com",
  messagingSenderId: "484194222370",
  appId: "1:484194222370:web:bee97047ebd6727629257b",
  measurementId: "G-PGEFWQ8R2B"
  };

  const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);

const auth = getAuth(app);
const storage = getStorage(app);

export {
    auth,
    storage,
    db
};