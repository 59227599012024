import Card from 'react-bootstrap/Card';
import {useEffect, useState} from "react";
import { collection } from "firebase/firestore";
import {getDocs} from "firebase/firestore";
import { db } from "../firebase-config";
import {useNavigate} from 'react-router-dom'

function Past() {
  const nav = useNavigate();
  const [aboutdata, setaboutdata] = useState([]);

  const packagedata3 = async () => {
    const colRef = collection(db, "Events");
    const snapshots = await getDocs(colRef);
    const docs = snapshots.docs.map(doc => doc.data());
        setaboutdata(docs);
  }

  useEffect(() => {
    packagedata3();
}, []);
    

    return (
       <div>
        <Card className='m-5'>
      <Card.Header className='m-3 fs-1 td'>Upcoming Events
      <h2 className='text-center'>----------o----------</h2></Card.Header>
      <Card.Body>
      <div>
             

             <div class="relative overflow-x-auto">
                 <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                     <thead class=" text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                         <tr>
                         </tr>
                     </thead>
                     <tbody>
                     {
                 aboutdata.filter((e)=>(new Date(e.date).getTime()>Date.now())).map(board=>(
                     <tr class="bg-white dark:bg-gray-800">
                     <th scope="row" class="px-6 py-4 font-bold text-gray-900 whitespace-nowrap dark:text-white">
                     <div dangerouslySetInnerHTML={{ __html:board.name}} />
                     </th>
                     <td class="px-6 py-4">
                         <a  className='font-bold cursor-pointer' style={{"textDecoration":"none"}} onClick={(e)=>{
                         nav('/event', { state: { id:board.id,past:false} });
                         }}>View</a>
                     </td>
                 </tr>
                 ))
               }
                     </tbody>
                 </table>
             </div>
             
                          </div>
      </Card.Body>
    </Card>
       </div>
    );
  }
  
  export default Past;