import logo from '../Images/logo.png'
import Image from 'react-bootstrap/Image'

function Footer() {
 


    return (
     <div className="bg-gray-200">
        <div className="p-3">
          <div className="container grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-7 xl:grid-cols-7 pt-3 gap-12 w-[90%]  justify-center">
          <a href="/member"  style={{textDecoration:"none"}} className="text-black fw-bold">Team</a>
           <a href="/future"  style={{textDecoration:"none"}} className="text-black fw-bold">Future Events</a>
           <a href="/past"  style={{textDecoration:"none"}} className="text-black fw-bold">Past Events</a>
           <a href="/" ><Image className="w-42 h-10"
                        src={logo}
                        alt="First slide"></Image></a>
           <a href="/news"  style={{textDecoration:"none"}} className="text-black fw-bold">Blog/News</a>
           <a href="/vol"  style={{textDecoration:"none"}} className="text-black fw-bold">Volunteer</a>
           <a href="/donationreg"  style={{textDecoration:"none"}} className="text-black fw-bold">Donate</a>
          </div>
          <div className=" grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-7 xl:grid-cols-7  gap-8 mt-5">
           {/* <a href=""  style={{textDecoration:"none"}} className="text-black">Gallery</a>
           <a href=""  style={{textDecoration:"none"}} className="text-black">Events</a> */}
          </div>
          <p className='text-center text-black fw-bold'>@{new Date().getFullYear()} Hand In Hand Trust : <a href="/conditions"  style={{textDecoration:"none"}} className="text-blue-700 fw-bold">Terms & Conditions</a></p>
        </div>
     </div>
    );
  }
  
  export default Footer;