import React ,{ useRef }from 'react';
import html2canvas from 'html2canvas';

const DonationCertificate = () => {

  const handleDownload = () => {
    window.print();
  };

  return (
    <div>
        <button onClick={() => handleDownload()} className="block mx-5 my-8 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Print</button>
    <div  className=" w-full max-w-md mx-auto bg-white shadow-md rounded-lg overflow-hidden m-5">
      <div className="px-6 py-4">
        <div className="font-bold text-xl mb-2">Donation Certificate</div>
        <div className="mb-4">
          <p className="text-gray-700 text-base">This certificate is presented to:</p>
          <p className="text-gray-900 font-bold text-lg">Yash Kumar Mishra</p>
        </div>
        <div className="mb-4">
          <p className="text-gray-700 text-base">In recognition of their generous donation of:</p>
          <p className="text-gray-900 font-bold text-lg">$788</p>
        </div>
        <div className="text-sm text-gray-600">Issued on {new Date().toLocaleDateString()}</div>
      </div>
    </div>
    </div>
  );
};

export default DonationCertificate;
