import React from "react";
import {useState,useEffect} from "react";
import {Link, useNavigate} from 'react-router-dom'
import {auth} from './firebase-config';
import {db} from './firebase-config'
import {setDoc, doc,getDoc} from 'firebase/firestore'
import {ref, uploadBytes, getDownloadURL} from "firebase/storage"
import {storage} from "./firebase-config";
import { onAuthStateChanged} from "firebase/auth";
import Card from 'react-bootstrap/Card';


function Regster() {
    const [user, setuser] = useState();
    const nav = useNavigate();
    const [validated, setValidated] = useState(false);
    const [d1, setd1] = useState(""); 
    const [d3, setd3] = useState(""); 
    const [d4, setd4] = useState(""); 
    const [d13, setd13] = useState(""); 
    const [d14, setd14] = useState(""); 
    const [d15, setd15] = useState(""); 
    const [d16, setd16] = useState("");  
    const [url, seturl] = useState(""); 
    const [d17, setd17] = useState("");  
    const [d6, setd6] = useState(""); 
    const [d18, setd18] = useState(""); 
    const [d19, setd19] = useState(""); 
    const [d9, setd9] = useState(0); 
    const [s,sets]= useState(false)
    
    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                setuser(user);
                packagedata3(user.uid);
            } else {
                setuser([]);
            }
        });
    }, [auth.currentUser]);

    const packagedata3 = async (uid) => {
        const docRef = doc(db, "Members",uid);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                sets(true)
              } else {
              }
      }
    

    
  const handleSubmit = (event) => {
    event.preventDefault();
    if( !d1,!d3,!d4){
        alert("please fill all details")
        return;
     }
         setDoc(doc(db, "Members", user.uid), {
         uid:user.uid,
         name:d1,
        stuimage:url,
         town:d3,
         mobile:d4,
         dist:d14,
         tah:d13,
         state:d15,
         country:d16,
         pin:d17,
         amount:d9,
         plan:d6,
         flat:d18,
         street:d19,
         });
         alert("Congrates you have registered");
  };

    const uploadimage = async (event) => {
      const imageRef = ref(storage, d1);
      if (event) {
          uploadBytes(imageRef, event).then(() => {
              getDownloadURL(imageRef).then((url) => {
                  seturl(url);
                  alert("Image Uploaded")
              }).catch((error) => {
                  console.log(error.message, "error geting the image url");
              })
          }).catch((error) => {
              console.log(error.message);
          })
      }
    };


    return (
        <div>
        {
            !s ? <div className="m-5">
                <div className='w-[80%] mx-auto my-5'>
      <div className='m-3 fs-1 td fw-bold'>
      Member's Registration
        <h2 className='text-center'>----------o----------</h2>
        </div>
      <div>
 <form onSubmit={handleSubmit}>
     <div class="grid gap-6 mb-6 md:grid-cols-2">
         <div>
             <label for="first_name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-start ml-2">Full name (as in Aadhar)</label>
             <input type="text" id="first_name" class="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required onChange={
                         (e) => {
                             setd1(e.target.value);
                         }
                     }/>
         </div>
         <div>
             <label for="company" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-start ml-2">Flat number</label>
             <input type="text" id="company" class="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required onChange={
                         (e) => {
                             setd18(e.target.value);
                         }
                     }/>
         </div>
         <div>
             <label for="company" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-start ml-2">Street</label>
             <input type="text" id="company" class="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required onChange={
                         (e) => {
                             setd19(e.target.value);
                         }
                     }/>
         </div>
         <div>
             <label for="company" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-start ml-2">Town/Village</label>
             <input type="text" id="company" class="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required onChange={
                         (e) => {
                             setd3(e.target.value);
                         }
                     }/>
         </div> 
         <div>
             <label for="company" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-start ml-2">Tehsil</label>
             <input type="text" id="company" class="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required onChange={
                         (e) => {
                             setd13(e.target.value);
                         }
                     }/>
         </div>  
         <div>
             <label for="company" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-start ml-2"> District</label>
             <input type="text" id="company" class="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required onChange={
                         (e) => {
                             setd14(e.target.value);
                         }
                     }/>
         </div>  
         <div>
             <label for="company" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-start ml-2">State</label>
             <input type="text" id="company" class="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required onChange={
                         (e) => {
                             setd15(e.target.value);
                         }
                     }/>
         </div>  
         <div>
             <label for="company" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-start ml-2">Pin Code</label>
             <input type="text" id="company" class="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required onChange={
                         (e) => {
                             setd17(e.target.value);
                         }
                     }/>
         </div> 
         <div>
             <label for="company" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-start ml-2">Country</label>
             <input type="text" id="company" class="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required onChange={
                         (e) => {
                             setd16(e.target.value);
                         }
                     }/>
         </div>   
         <div class="mb-6">
         <label for="password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-start ml-2">Contact Number: Country Code (+91) xxx---xx
 </label>
         <input type="text" id="password" class="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required onChange={
                         (e) => {
                             setd4(e.target.value);
                         }
                     }/>
     </div>
     </div>
     <div className="m-3 ">
     <label for="confirm_password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your Photo (Face Only)</label>
             <input type="file"
                 onChange={
                     (e) => {
                         if (e.target.files[0]) {
                             uploadimage(e.target.files[0])
                         }
                     }
                 }/>
         </div>
         <div>
             <label for="visitors" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-start ml-2">Plan</label>
             <select id="countries" class="mb-6 bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
 onChange={(e)=>{
     setd6(e.target.value)
 }}
 >
   <option selected>Choose...</option>
   <option value="Monthly 200 Rupees">Monthly 200 Rupees</option>
   <option value="Yearly 2000 Rupees">Yearly 2000 Rupees</option>
   <option value="I want to enter my own amount">I want to enter my own amount</option>
 </select>
         </div>
         {
             d6==="I want to enter my own amount" && (
 <div class="mb-6">
         <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-start ml-2">Amount</label>
         <input type="Number" class="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required onChange={
                         (e) => {
                             setd9(e.target.value);
                         }
                     }/>
     </div>
             )
         } 
     <button type="submit" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" onClick={(e)=>{
         handleSubmit(e)
     }}>Register</button>
 </form>
 </div>
    </div>
 </div>: <div className="flex items-center justify-center h-screen bg-gray-100">
      <div className="text-center">
        <h1 className="text-4xl font-semibold text-gray-800">Hurray....</h1>
        <p className="text-lg text-blue-600 mt-4">
           You are a member ......
        </p>
      </div>
    </div>
          }
          </div>
    );
  }
  
  export default Regster;