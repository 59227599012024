import Card from 'react-bootstrap/Card';
import {useEffect, useState} from "react";
import { collection } from "firebase/firestore";
import {getDocs} from "firebase/firestore";
import { db } from "../firebase-config";
import {auth} from '../firebase-config';
import { onAuthStateChanged} from "firebase/auth";
import { doc,getDoc } from "firebase/firestore";
import {useNavigate} from 'react-router-dom'
import { Button, Modal } from 'react-bootstrap';

function Student() {
    const nav = useNavigate();
  const [boards, setboards] = useState([]);
  const [subjects, setsubjects] = useState([]);
  const [board, setboard] = useState("");
  const [class1, setclass1] = useState("");
  const [chapters,setchapters] = useState([])
  const [subject, setsubject] = useState("");
  const [classdata,setclassdata] = useState([])
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => {
    setShowModal(false)
  };

  const handle123 = () => {
    setShowModal(false)
    nav("/registerstu");
  };
  
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
        if (user) {
            packagedata3(user.uid);
        } else {
        }
    });
    fidata1()
    fidata2()
    fidata3()
    fidata4()
}, [auth.currentUser]);

  const packagedata3 = async (uid) => {
    const docRef = doc(db, "Students",uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          } else {
            setShowModal(true);
          }
  }

  const fidata1 = async () => {
    const colRef = collection(db, "Boards");
    const snapshots = await getDocs(colRef);
    const docs = snapshots.docs.map(doc => doc.data());
        setboards(docs);
  }

  const fidata2 = async () => {
    const colRef = collection(db, "Subjects");
    const snapshots = await getDocs(colRef);
    const docs = snapshots.docs.map(doc => doc.data());
        setsubjects(docs);
  }

  const fidata3 = async () => {
    const colRef = collection(db, "chapters");
    const snapshots = await getDocs(colRef);
    const docs = snapshots.docs.map(doc => doc.data());
        setchapters(docs);
  }

  const fidata4 = async () => {
    const colRef = collection(db, "class");
    const snapshots = await getDocs(colRef);
    const docs = snapshots.docs.map(doc => doc.data());
        setclassdata(docs);
  }
    return (
       <div>
         <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Registration Reminder for Online Classes with Hand in Hand Trust</Modal.Title>
        </Modal.Header>
        <Modal.Body><div>
            <h5 className="text-start">Dear Student,</h5>
            <h5 className="text-start py-2">We hope this message finds you well. We'd like to extend a warm invitation to join our online classes provided by Hand in Hand Trust. As of now, we've noticed that you haven't registered as a student yet.</h5>
            <h5 className="text-start py-2">At Hand in Hand Trust, we're dedicated to providing enriching educational experiences that align with your lessons, all accessible online. What's more, we're thrilled to inform you that these classes come to you at absolutely no cost.
</h5>
<h5 className="text-start py-2">
All it takes is a simple, one-time registration to unlock a world of learning opportunities tailored just for you. Once registered, you'll gain access to a plethora of educational resources and engaging classes to enrich your learning journey.</h5>
<h5 className="text-start py-2">Should you encounter any difficulties or have any queries, please don't hesitate to reach out to us via WhatsApp at +919458588545. We're here to assist you every step of the way.
We eagerly await your registration so you can start enjoying our classes and embark on a fulfilling educational journey with Hand in Hand Trust.</h5>
           <h5 className="text-start py-2">Thanks with warm regards,</h5>
           <h5 className="text-start py-2">Team <br></br>
            Hand in Hand Trust
            </h5>
           </div></Modal.Body>
        <Modal.Footer>
        <div class="flex items-start mb-6">
        <input id="default-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
        <label for="default-checkbox" class="text-sm font-medium text-black dark:text-gray-300 ml-1"> I am Agree
    </label>
    </div>
          <Button variant="success" onClick={handle123}>
          Register
          </Button>
        </Modal.Footer>
      </Modal>
        <Card className='w-[80%] mx-auto my-5'>
        <Card.Header className='m-3 fs-1 td fw-bold'>Students
      <h2 className='text-center'>----------o----------</h2>
      </Card.Header>
      <Card.Body>
      <div>
<div className='flex flex-row gap-32 mb-5 justify-between m-2'> 
<div>  
<label for="countries" class="block mb-2 fw-bold font-medium text-gray-900 dark:text-white">Board</label>
<select id="countries" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
onChange={(e)=>{
    setboard(e.target.value)
}}
>
  <option selected>Choose a Board</option>
  {
    boards.map(board=>(
      <option value={board.Country}>{board.Country}</option>
    ))
  }
</select>
</div>
<div>
<label for="countries" class="block mb-2 fw-bold font-medium text-gray-900 dark:text-white">Class</label>
<select id="countries" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
onChange={(e)=>{
    setclass1(e.target.value)
}}
>
  <option selected>Choose a Class</option>
  {
    classdata.map(board=>(
      <option value={board.Subject}>{board.Subject}</option>
    ))
  }
</select>
</div>
<div>
<label for="countries" class="block mb-2 fw-bold font-medium text-gray-900 dark:text-white">Subject</label>
<select id="countries" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
onChange={(e)=>{
    setsubject(e.target.value)
}}
>
  <option selected>Choose a Subject</option>
  {
    subjects.filter((e)=>((e.board===board && e.stand===class1))).map(board=>(
      <option value={board.Subject}>{board.Subject}</option>
    ))
  }
</select>
</div>
</div>


<div class="relative overflow-x-auto">
    <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
        <thead class=" text-gray-900 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
                <th scope="col" class="px-6 py-3 fw-bold">
                    Chapter name
                </th>
                <th scope="col" class="px-6 py-3 fw-bold">
                    Open
                </th>
            </tr>
        </thead>
        <tbody>
        {
    chapters.filter((e)=>((e.academics==="students"))).filter((e)=>((e.board===board && e.stand===class1 && e.Subject===subject))).map(board=>(
        <tr class="bg-white dark:bg-gray-800">
        <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
            {board.name}
        </th>
        <td class="px-6 py-4">
            <a href={board.link} className='font-bold ' style={{"textDecoration":"none"}}>Open</a>
        </td>
    </tr>
    ))
  }
        </tbody>
    </table>
</div>

             </div>
      </Card.Body>
    </Card>
       </div>
    );
  }
  
  export default Student;