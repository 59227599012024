import Card from 'react-bootstrap/Card';

function Policies() {

    return (
       <div>
        <Card className='m-5'>
      <Card.Header className='m-3 fs-1'>Refund & Return</Card.Header>
      <h3 className='text-center'>----------o----------</h3>
      <Card.Title>
      Non-Refundable Transactions: All transactions processed through the Hand in Hand Trust payment gateway are deemed final and non-refundable. Once a transaction is completed, it cannot be reversed, refunded, or returned under any circumstances.
      </Card.Title>
    </Card>
       </div>
    );
  }
  
  export default Policies;