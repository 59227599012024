import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, {useState, useEffect} from "react";
import Navbar from './Components/Navbar';
import { Route, Routes, BrowserRouter  } from 'react-router-dom';
import Footer from './Components/Footer';
import Home from './Components/Home';
import About from './Components/About';
import Member from './Components/Member';
import Contact from './Components/Contact';
import Login from './Components/Login';
import Register from './Components/Register';
import Notfound from "./Components/Notfound"
import Notlogin from "./Components/Notlogin"
import { auth} from "../src/Components/firebase-config";
import { onAuthStateChanged} from "firebase/auth";
import Etc from "./Components/Academics/Etc"
import Students from "./Components/Academics/Students"
import Farmer from "./Components/Academics/Farmer"
import Dance from "./Components/Academics/Dance"
import Women from "./Components/Academics/Women"
import Registerstudent from "./Components/Academics/Register"
import Past from "./Components/Events/Past"
import Future from "./Components/Events/Future"
import Profile from './Components/Profile';
import Event from './Components/Events/Event'
import Job from './Components/Academics/job'
import Weather from './Components/Academics/weather'
import Farmer1 from './Components/Academics/Farmer1'
import Dance1 from './Components/Academics/Dance1'
import Women1 from './Components/Academics/Women1'
import Memberreg from './Components/Memberreg'
import Donationreg from './Components/Donation/Donationreg'
import Conditions from './Components/Conditions';
import Policies from './Components/Policies';
import Blogs from './Components/Blogs'
import Entertainment from './Components/Academics/Entertainment'
import Donation2 from './Components/Donation/Donation2'
import Volunterr from './Components/Volunterr'
import Vcard from './Components/cards/vcard'
import Dcard from './Components/cards/dcard'


function App() {
  const [ya, setya] = useState(false);

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                setya(true);
            } else {
                setya(false);
            }
        });
    }, [auth.currentUser]);
  


  
  return (
    <div className="App bg-yellow-100">
      <BrowserRouter>
      <Navbar />
      <Routes> 
        {
          ya ?(
            <>
             <Route path='/' element={<Home/>}></Route>
      <Route path='/news' element={<Blogs/>}></Route>
      <Route path='/about' element={<About/>}></Route>
      <Route path='/member' element={<Member/>}></Route>
      <Route path='/past' element={<Past/>}></Route>
      <Route path='/future' element={<Future/>}></Route>
      <Route path='/contact' element={<Contact/>}></Route>
      <Route path='/women' element={<Women/>}></Route>
      <Route path='/students' element={<Students/>}></Route>
      <Route path='/farmer' element={<Farmer/>}></Route>
      <Route path='/dance' element={<Dance/>}></Route>
      <Route path='/farmer1' element={<Farmer1/>}></Route>
      <Route path='/dance1' element={<Dance1/>}></Route>
      <Route path='/women1' element={<Women1/>}></Route>
      <Route path='/etc' element={<Etc/>}></Route>
      <Route path='/profile' element={<Profile/>}></Route>
      <Route path='/event' element={<Event/>}></Route>
      <Route path='/job' element={<Job/>}></Route>
      <Route path='/weather' element={<Weather/>}></Route>
      <Route path='/memberreg' element={<Memberreg/>}></Route>
      <Route path='/donationreg' element={<Donationreg/>}></Route>
      <Route path='/conditions' element={<Conditions/>}></Route>
      <Route path='/policies' element={<Policies/>}></Route>
      <Route path='/enter' element={<Entertainment/>}></Route>
      <Route path='/donation2' element={<Donation2/>}></Route>
      <Route path='/vol' element={<Volunterr/>}></Route>
      <Route path='/vcard' element={<Vcard/>}></Route>
      <Route path='/dcard' element={<Dcard/>}></Route>
      <Route path='/registerstu' element={<Registerstudent/>}></Route>
            </>
          ) :(
            <>
            <Route path='/' element={<Home/>}></Route>
            <Route path='/news' element={<Blogs/>}></Route>
      <Route path='/about' element={<About/>}></Route>
             <Route path='/profile' element={<Notlogin/>}></Route>
             <Route path='/member' element={<Member/>}></Route>
             <Route path='/past' element={<Past/>}></Route>
      <Route path='/future' element={<Future/>}></Route>
      <Route path='/contact' element={<Contact/>}></Route>
      <Route path='/event' element={<Event/>}></Route>
      <Route path='/login' element={<Login/>}></Route>
      <Route path='/register' element={<Register/>}></Route>
      <Route path='/women' element={<Notlogin/>}></Route>
      <Route path='/students' element={<Notlogin/>}></Route>
      <Route path='/farmer' element={<Notlogin/>}></Route>
      <Route path='/dance' element={<Notlogin/>}></Route>
      <Route path='/enter' element={<Notlogin/>}></Route>
      <Route path='/etc' element={<Notlogin/>}></Route>
      <Route path='/registerstu' element={<Notlogin/>}></Route>
      <Route path='/job' element={<Notlogin/>}></Route>
      <Route path='/weather' element={<Notlogin/>}></Route>
      <Route path='/farmer1' element={<Notlogin/>}></Route>
      <Route path='/dance1' element={<Notlogin/>}></Route>
      <Route path='/women1' element={<Notlogin/>}></Route>
      <Route path='/memberreg' element={<Notlogin/>}></Route>
      <Route path='/donation2' element={<Notlogin/>}></Route>
      <Route path='/donationreg' element={<Notlogin/>}>
      </Route>
      <Route path='/vcard' element={<Notlogin/>}></Route>
      <Route path='/dcard' element={<Notlogin/>}></Route>
      <Route path='/conditions' element={<Conditions/>}></Route>
      <Route path='/policies' element={<Policies/>}></Route>
      <Route path='/vol' element={<Notlogin/>}></Route>
            </>
          )
        }
      <Route path="*" element={<Notfound />} />
      </Routes>
      <Footer/>
      </BrowserRouter>
    </div>
  );
}

export default App;
