import React, { useEffect, useState } from "react";
import {useNavigate} from 'react-router-dom'
import { collection } from "firebase/firestore";
import {getDocs} from "firebase/firestore";
import { db } from "./firebase-config";
import { auth} from "./firebase-config";
import { onAuthStateChanged} from "firebase/auth";
import Card from 'react-bootstrap/Card';

function Profile() {
  const [profiledata,setprofiledata] = useState([]);
  const [vol,setvol] = useState([]);
  const [donation,setdonation] = useState([]);
  const [uid,setuid] = useState("")
  const nav = useNavigate();


  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
        if (user) {
            setuid(user.uid)
        } else {
        }
    });
    fetchData()
    fetchData1()
    fetchData2()
}, [auth.currentUser]);

  const fetchData = async () => {
    const colRef = collection(db, "User");
    const snapshots = await getDocs(colRef);
    const docs = snapshots.docs.map(doc => doc.data());
        setprofiledata(docs);
  }

  const fetchData1 = async () => {
    const colRef = collection(db, "Donation");
    const snapshots = await getDocs(colRef);
    const docs = snapshots.docs.map(doc => doc.data());
        setdonation(docs);
        
  }
  const fetchData2 = async () => {
    const colRef = collection(db, "Volunteer");
    const snapshots = await getDocs(colRef);
    const docs = snapshots.docs.map(doc => doc.data());
        setvol(docs);
        
  }

  return (
    <div className="p-8  ">
        <div className="flex items-center justify-center m-6 ">
        {
          profiledata.filter((e)=>(e.uid===uid)).map(profiledata =>(
          <div className="max-w-md xl:max-w-2xl bg-white ">
            <div className="shadow-xl   p-6 md:p-8 lg:p-10 xl:p-12">
              <div className="photo-wrapper mx-auto mb-6 ">
                <img
                  className="w-40 h-40 object-cover rounded-full mx-auto border-solid border-2 border-black"
                  src={profiledata.profileimage}
                  alt="Profile"
                />
              </div>
              {profiledata.name && (
                <div className="text-center">
                  <h3 className="text-base lg:text-2xl font-bold leading-8">
                    {profiledata.name}
                  </h3>
                </div>
              )}
              <table className="text-lg lg:text-base my-6">
                <tbody>
                  {profiledata.email && (
                    <tr>
                      <td className="px-2 py-2 text-gray-500 font-semibold">
                        Email
                      </td>
                      <td className="px-2 py-2">{profiledata.email}</td>
                    </tr>
                  )}
                  <tr>
                      <td className="px-2 py-2 text-gray-500 font-semibold">
                        Id
                      </td>
                      <td className="px-2 py-2">{uid}</td>
                    </tr>
                  <tr>
                      <td className="px-2 py-2 text-gray-500 font-semibold">
                        Contact
                      </td>
                      <td className="px-2 py-2">{profiledata.Contact}</td>
                    </tr>
                  {profiledata.address && (
                    <tr>
                      <td className="px-2 py-2 text-gray-500 font-semibold">
                        Address
                      </td>
                      <td className="px-2 py-2">{profiledata.address}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          ))}
        </div>
        <Card className='m-5'>
      <Card.Header className='m-3 fs-1 td'>Donation History
      <h2 className='text-center'>----------o----------</h2>
      </Card.Header>
      <Card.Body>
      <div>
<div class="relative overflow-x-auto">
    <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
        <thead class="fw-bold text-gray-900 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
            <th scope="col" class="px-6 py-3">
                    Title
                </th>
                <th scope="col" class="px-6 py-3">
                    Open
                </th>
            </tr>
        </thead>
        <tbody>
        {
    donation.filter((e)=>((e.uid===uid))).map(board=>(
        <tr class="bg-white dark:bg-gray-800">
        <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
            
            <div dangerouslySetInnerHTML={{ __html:board.Purpose}} />
        </th>
        <td class="px-6 py-4">
            <a  className='font-bold ' style={{"textDecoration":"none"}}>Open</a>
        </td>
    </tr>
    ))
  }
        </tbody>
    </table>
</div>

             </div>
      </Card.Body>
    </Card>
    <Card className='m-5'>
      <Card.Header className='m-3 fs-1 td'>Volunteer History
      <h2 className='text-center'>----------o----------</h2>
      </Card.Header>
      <Card.Body>
      <div>
<div class="relative overflow-x-auto">
    <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
        <thead class="fw-bold text-gray-900 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
            <th scope="col" class="px-6 py-3">
                    Title
                </th>
                {/* <th scope="col" class="px-6 py-3">
                    Color
                </th>
                <th scope="col" class="px-6 py-3">
                    Category
                </th> */}
                <th scope="col" class="px-6 py-3">
                    Open
                </th>
            </tr>
        </thead>
        <tbody>
        {
    vol.filter((e)=>((e.id1===uid))).map(board=>(
        <tr class="bg-white dark:bg-gray-800">
        <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
            {board.ename}
        </th>
       
        <td class="px-6 py-4">
            <a className='font-bold cursor-pointer' style={{"textDecoration":"none"}} onClick={(e)=>{
                         nav('/vcard', { state: { id:board.id} });
                         }}>Open</a>
        </td>
    </tr>
    ))
  }
        </tbody>
    </table>
</div>

             </div>
      </Card.Body>
    </Card>
    </div>
  );
}

export default Profile;