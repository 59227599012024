import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Button from 'react-bootstrap/Button';
import {useEffect, useState,useRef} from "react";
import Image from 'react-bootstrap/Image'
import './Home.css'
import logo from '../Images/logo.png'
import { auth} from "./firebase-config";
import {signOut, onAuthStateChanged} from "firebase/auth";
import pic from "../Images/profile.jpg"
import { collection } from "firebase/firestore";
import {getDocs} from "firebase/firestore";
import { db } from "./firebase-config";
import { doc,getDoc } from "firebase/firestore";

function Navbar1() {
  const [user1,setuser1]= useState([])
  const [uid,setuid] = useState("")
  const [xyzdata,setxyzdata] = useState([])
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  const handleDropdownClick = () => {
    setIsDropdownOpen((prevState) => !prevState);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        isDropdownOpen &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target)
      ) {
        closeDropdown();
      }
    };

    if (isDropdownOpen) {
      document.addEventListener("click", handleOutsideClick);
    } else {
      document.removeEventListener("click", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [isDropdownOpen]);

    const [ya, setya] = useState(false);

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                setya(true);
                setuid(user.uid)
            } else {
                setya(false);
                setuid("")
            }
        });
        fidata1()
        fidata3()
    }, [auth.currentUser]);

    const fidata1 = async () => {
      const colRef = collection(db, "User");
      const snapshots = await getDocs(colRef);
      const docs = snapshots.docs.map(doc => doc.data());
          setuser1(docs);
    }

    const fidata3 = async () => {
      const docRef = doc(db, "academics","showornot");
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
          setxyzdata(docSnap.data())
        } else {
        }
    }


    const logout = async () => {
        signOut(auth).then(() => {
            alert("Successfully logout");
        }).catch((error) => {});
    };


    return (
        <Navbar bg="white" variant="white" expand="lg">
            <Container>
                <Navbar.Brand href="/">
                    <Image className="navimage"
                        src={logo}
                        alt="First slide"></Image>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link href="/"></Nav.Link>
                    </Nav>
                    <Nav>
                    <Nav.Link href="/about" className='fw-bold '>About us</Nav.Link>
                    <NavDropdown className='fw-bold text-dark ' title="Academics " id="basic-nav-dropdown">
                                <NavDropdown.Item href="/students" >
                                Students
                                </NavDropdown.Item>
                                {
                        xyzdata.s7=="yes" && (
                               <NavDropdown.Item href="/farmer1">
                                Farmer
                                </NavDropdown.Item>
                        )
                      }
                       {
                        xyzdata.s8=="yes" && (
                          <NavDropdown.Item href="/women1">
                         Women
                          </NavDropdown.Item>
                        )
                      }
                       {
                        xyzdata.s9=="yes" && (
                          <NavDropdown.Item href="/dance1">
                          Dance
                          </NavDropdown.Item>
                        )
                      }
                                {
                        xyzdata.s1=="yes" && (
                               <NavDropdown.Item href="/farmer">
                                Yoga
                                </NavDropdown.Item>
                        )
                      }
                       {
                        xyzdata.s2=="yes" && (
                          <NavDropdown.Item href="/women">
                          Future study chart
                          </NavDropdown.Item>
                        )
                      }
                       {
                        xyzdata.s3=="yes" && (
                          <NavDropdown.Item href="/dance">
                          Health 
                          </NavDropdown.Item>
                        )
                      }
                       {
                        xyzdata.s4=="yes" && (
                          <NavDropdown.Item href="/etc">
                          Government policie
                          </NavDropdown.Item>
                        )
                      }
                       {
                        xyzdata.s5=="yes" && (
                          <NavDropdown.Item href="/weather">
                          Weather
                          </NavDropdown.Item>
                        )
                      }
                      {
                        xyzdata.s6=="yes" && (
                          <NavDropdown.Item href="/job">
                          Jobs Opportunity
                          </NavDropdown.Item>
                        )
                      }
                      {
                        xyzdata.s10=="yes" && (
                          <NavDropdown.Item href="/enter">
                          Entertainment
                          </NavDropdown.Item>
                        )
                      }
                           </NavDropdown>
                           <NavDropdown className='fw-bold text-dark ' title="Events" id="basic-nav-dropdown">
                                <NavDropdown.Item href="/past">
                                Past Events
                                </NavDropdown.Item>
                                <NavDropdown.Item  href="/future">
                                Up Coming Events 
                                </NavDropdown.Item>
                           </NavDropdown>
                    {/* <NavDropdown className='fw-bold text-dark fs-5' title="Campaigns" id="basic-nav-dropdown">
                                <NavDropdown.Item >
                                </NavDropdown.Item>
                           </NavDropdown> */}
                           <Nav.Link href="/donationreg" className='fw-bold '>Donate</Nav.Link>
                        <Nav.Link href="/memberreg" className='fw-bold '>Membership</Nav.Link>
                        <Nav.Link href="/contact" className='fw-bold '>Contact</Nav.Link>
                    </Nav>
                    <> {
                            ya ? (
                                <>
                                <div
                                  className="relative inline-block text-left "
                                  ref={dropdownRef}
                                >
                                  <button
                                    onClick={handleDropdownClick}
                                    className="w-12 h-12 p-1 relative group rounded-full overflow-hidden focus:outline-none"
                                  >
                                    {
          user1.filter((e)=>(e.uid===uid)).map(profiledata =>(
            <img
            className="object-cover w-full h-full rounded-full border-solid border-2 border-black group-hover:opacity-70"
            src={profiledata.profileimage || pic}
            alt="Profile"
          />
           ))}
                                  </button>
                                  {isDropdownOpen && (
                                    <div className=" origin-top-right absolute right-0 mt-3 z-1 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                                      <div className="p-1">
                                        <a
                                          href="/profile"
                                          className="block px-4 py-2 text-sm font-bold text-gray-900 hover:bg-gray-100"
                                          onClick={closeDropdown}
                                          style={{"textDecoration":'none'}}
                                        >
                                          My Account
                                        </a>
                                        <div className="block px-4 py-2 text-sm font-bold text-red-700 hover:bg-gray-100">
                                        <button onClick={() => { 
                                          logout();
                                          closeDropdown(); }}>
                                          Logout
                                        </button>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </>
                            ) : <Button variant="outline-success"className='mx-2 fw-bold' href='/login'>LOG IN</Button>
                        } </>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default Navbar1;