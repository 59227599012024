import React from "react";
import {useState,useEffect} from "react";
import {Link, useNavigate} from 'react-router-dom'
import {auth} from './firebase-config';
import {db} from './firebase-config'
import {setDoc, doc,getDoc,collection,getDocs} from 'firebase/firestore'
import {ref, uploadBytes, getDownloadURL} from "firebase/storage"
import {storage} from "./firebase-config";
import { onAuthStateChanged} from "firebase/auth";
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image'
import sign from '../Images/sign.png'


function Regster() {
    const [user, setuser] = useState();
    const nav = useNavigate();
    const [vdata,setvdata] = useState([]);
    const [d1, setd1] = useState(""); 
    const [d3, setd3] = useState(""); 
    const [d4, setd4] = useState("")  
    const [url, seturl] = useState("");
    const [d18, setd18] = useState(""); 
    const [d19, setd19] = useState("");
    const [data, setdata] = useState([]); 
    const [s,sets]= useState(false)
    const id=Math.random().toString();
    
    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                setuser(user);
                packagedata3();
                packagedata4();
            } else {
                setuser([]);
            }
        });
    }, [auth.currentUser]);

    const packagedata3 = async () => {
        const docRef = doc(db, "Volunteer","form");
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                setdata(docSnap.data())
              } else {
              }
      }
    
      const packagedata4 = async () => {
        const colRef = collection(db, "Volunteer");
        const snapshots = await getDocs(colRef);
        const docs = snapshots.docs.map(doc => doc.data());
            setvdata(docs);
      }

    
  const handleSubmit = (event) => {
    event.preventDefault();
    if( !d1,!d3,!d18,!d19){
        alert("please fill all details")
        return;
     }
         setDoc(doc(db, "Volunteer",id), {
         id1:d1,
         id:id,
        stuimage:url,
        ename:data.name,
         lname:d3,
         fname:d18,
         mname:d19,
         from:data.from,
         till:data.till
         });
         alert("Congrates you have registered");
         packagedata3();
                packagedata4();
  };

    const uploadimage = async (event) => {
      const imageRef = ref(storage, id);
      if (event) {
          uploadBytes(imageRef, event).then(() => {
              getDownloadURL(imageRef).then((url) => {
                  seturl(url);
                  alert("Image Uploaded")
              }).catch((error) => {
                  console.log(error.message, "error geting the image url");
              })
          }).catch((error) => {
              console.log(error.message);
          })
      }
    };


    return (
        <div>
        {
            vdata.filter((e)=>(e.ename===data.name)).length<data.total ? <div className="m-5">
                <div className='w-[80%] mx-auto my-5'>
      <div className='m-3 fs-1 td fw-bold'>
      Volunteer's Registration
        <h2 className='text-center'>----------o----------</h2>
        </div>
      <div>
 <form onSubmit={handleSubmit}>
     <div class="grid gap-6 mb-6 md:grid-cols-2">
         <div>
             <label for="first_name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-start ml-2">Your Identity Number </label>
             <input type="text" id="first_name" class="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required onChange={
                         (e) => {
                             setd1(e.target.value);
                         }
                     }/>
         </div>
         <div>
             <label for="company" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-start ml-2">First Name</label>
             <input type="text" id="company" class="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required onChange={
                         (e) => {
                             setd18(e.target.value);
                         }
                     }/>
         </div>
         <div>
             <label for="company" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-start ml-2">Middle Name</label>
             <input type="text" id="company" class="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required onChange={
                         (e) => {
                             setd19(e.target.value);
                         }
                     }/>
         </div>
         <div>
             <label for="company" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-start ml-2">Last Name</label>
             <input type="text" id="company" class="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required onChange={
                         (e) => {
                             setd3(e.target.value);
                         }
                     }/>
         </div> 
         <div>
             <label for="company" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-start ml-2">Event Name</label>
             <input type="text" id="company" class="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required value={data.name}/>
         </div>  
         <div>
             <label for="company" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-start ml-2">Valid From</label>
             <input type="text" id="company" class="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required value={data.from}/>
         </div>  
         <div>
             <label for="company" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-start ml-2">Valid Till</label>
             <input type="text" id="company" class="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required value={data.till}/>
         </div>  
     </div>
     <div className="m-3 ">
     <label for="confirm_password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your Face Photograph </label>
             <input type="file"
                 onChange={
                     (e) => {
                         if (e.target.files[0]) {
                             uploadimage(e.target.files[0])
                         }
                     }
                 }/>
         </div> 
         <div className="m-3 ">
         <label for="company" class="block mb-2 text-base font-medium text-gray-900 dark:text-white text-start ml-2">Sign authority : Trustee & Event Manager </label>
         <Image className="navimage"
                        src={sign}
                        alt="First slide"></Image>
         </div>
     <button type="submit" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" onClick={(e)=>{
         handleSubmit(e)
     }}>Register</button>
 </form>
 </div>
    </div>
 </div>: <div className="flex items-center justify-center h-screen bg-gray-100">
      <div className="text-center">
        <h1 className="text-4xl font-semibold text-gray-800">Sorry....</h1>
        <p className="text-lg text-blue-600 mt-4">
        Volunteer seats are full ......
        </p>
      </div>
    </div>
          }
          </div>
    );
  }
  
  export default Regster;