import React from "react";
import {useState,useEffect} from "react";
import {Link, useNavigate} from 'react-router-dom'
import {auth} from '../firebase-config';
import {updateProfile} from "firebase/auth";
import {db} from '../firebase-config'
import {setDoc, doc} from 'firebase/firestore'
import Form from 'react-bootstrap/Form';
import {ref, uploadBytes, getDownloadURL} from "firebase/storage"
import {storage} from "../firebase-config";
import { onAuthStateChanged} from "firebase/auth";
import Card from 'react-bootstrap/Card';


function Regster() {
    const [user, setuser] = useState();
    const nav = useNavigate();
    const [validated, setValidated] = useState(false);
    const [d1, setd1] = useState(""); 
    const [d2, setd2] = useState(""); 
    const [d3, setd3] = useState(""); 
    const [d4, setd4] = useState(0); 
    const [d5, setd5] = useState(""); 
    const [d6, setd6] = useState(""); 
    const [d7, setd7] = useState(""); 
    const [d8, setd8] = useState(""); 
    const [d9, setd9] = useState(""); 
    const [d10, setd10] = useState(""); 
    const [d11, setd11] = useState(""); 
    const [d12, setd12] = useState("");
    const [d13, setd13] = useState(""); 
    const [d14, setd14] = useState(""); 
    const [d15, setd15] = useState(""); 
    const [d16, setd16] = useState("");  
    const [url, seturl] = useState(""); 
    const [d17, setd17] = useState(0);  
    const [url1, seturl1] = useState("");
    const [url2, seturl2] = useState("");  

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                setuser(user);
            } else {
                setuser([]);
            }
        });
    }, [auth.currentUser]);

    
  const handleSubmit = (event) => {
    event.preventDefault();
    if( !d1,!d2,!d3,!d4,!d5,!d6,!d7,!d8,!d9,!d10){
        alert("please fill all details")
        return;
     }
         setDoc(doc(db, "Students", user.uid), {
         uid:user.uid,
         name:d1,
         email:d9,
        stuimage:url,
         schoolme:d6,
         board:d7,
         adhar:d2,
         address:d3,
         mobile:d4,
         school:d5,
         class:d8,
         sec:d12,
         adharmother:d10,
         adharfather:d11,
         dist:d14,
         tah:d13,
         state:d15,
         country:d16,
         pin:d17,
         adhar1:url1,
         adh2:url2
         });
         nav("/students");
  };

    const uploadimage = async (event) => {
      const imageRef = ref(storage, d1);
      if (event) {
          uploadBytes(imageRef, event).then(() => {
              getDownloadURL(imageRef).then((url) => {
                  seturl(url);
                  alert("Image Uploaded")
              }).catch((error) => {
                  console.log(error.message, "error geting the image url");
              })
          }).catch((error) => {
              console.log(error.message);
          })
      }
    };

    const uploadimage1 = async (event) => {
        const imageRef = ref(storage, d2);
        if (event) {
            uploadBytes(imageRef, event).then(() => {
                getDownloadURL(imageRef).then((url) => {
                    seturl1(url);
                    alert("Image Uploaded")
                }).catch((error) => {
                    console.log(error.message, "error geting the image url");
                })
            }).catch((error) => {
                console.log(error.message);
            })
        }
      };

      const uploadimage2 = async (event) => {
        const imageRef = ref(storage, user.uid);
        if (event) {
            uploadBytes(imageRef, event).then(() => {
                getDownloadURL(imageRef).then((url) => {
                    seturl2(url);
                    alert("Image Uploaded")
                }).catch((error) => {
                    console.log(error.message, "error geting the image url");
                })
            }).catch((error) => {
                console.log(error.message);
            })
        }
      };

    return (
        <div className="m-5">
           <div className='w-[80%] mx-auto my-5'>
      <div className='m-3 fs-1 td fw-bold'>
      Student's Registration
        <h2 className='text-center'>----------o----------</h2>
        </div>
      <div>
<form onSubmit={handleSubmit}>
    <div class="grid gap-6 mb-6 md:grid-cols-2">
        <div>
            <label for="first_name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-start ml-2">Full name (As in Aadhar/Government ID)</label>
            <input type="text" id="first_name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required onChange={
                        (e) => {
                            setd1(e.target.value);
                        }
                    }/>
        </div>
        <div>
            <label for="last_name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-start ml-2">Aadhar No. </label>
            <input type="text" id="last_name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required onChange={
                        (e) => {
                            setd2(e.target.value);
                        }
                    }/>
        </div>
        <div>
            <label for="company" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-start ml-2">Address (Flat number, Street, Town/Village)</label>
            <input type="text" id="company" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required onChange={
                        (e) => {
                            setd3(e.target.value);
                        }
                    }/>
        </div> 
        <div>
            <label for="company" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-start ml-2">Tehsil</label>
            <input type="text" id="company" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required onChange={
                        (e) => {
                            setd13(e.target.value);
                        }
                    }/>
        </div>  
        <div>
            <label for="company" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-start ml-2"> District</label>
            <input type="text" id="company" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required onChange={
                        (e) => {
                            setd14(e.target.value);
                        }
                    }/>
        </div>  
        <div>
            <label for="company" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-start ml-2">State</label>
            <input type="text" id="company" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required onChange={
                        (e) => {
                            setd15(e.target.value);
                        }
                    }/>
        </div>  
        <div>
            <label for="company" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-start ml-2">Pin Code</label>
            <input type="Number" id="company" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required onChange={
                        (e) => {
                            setd17(e.target.value);
                        }
                    }/>
        </div> 
        <div>
            <label for="company" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-start ml-2">Country</label>
            <input type="text" id="company" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required onChange={
                        (e) => {
                            setd16(e.target.value);
                        }
                    }/>
        </div>   
        <div class="mb-6">
        <label for="password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-start ml-2">Mobile Number (If Possible : Whatsapp)</label>
        <input type="Number" id="password" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required onChange={
                        (e) => {
                            setd4(e.target.value);
                        }
                    }/>
    </div>
        <div>
            <label for="website" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-start ml-2">School Name</label>
            <input type="text" id="website" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required onChange={
                        (e) => {
                            setd5(e.target.value);
                        }
                    }/>
        </div>
        <div>
            <label for="visitors" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-start ml-2">School Medium</label>
            <select id="countries" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
onChange={(e)=>{
    setd6(e.target.value)
}}
>
  <option selected>Choose...</option>
  <option value="Hindi">Hindi</option>
  <option value="English">English</option>
</select>
        </div>
        <div>
            <label for="visitors" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-start ml-2">Board</label>
            <input type="text" id="visitors" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required onChange={
                        (e) => {
                            setd7(e.target.value);
                        }
                    }/>
        </div>
        <div>
            <label for="visitors" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-start ml-2">Class</label>
            <input type="text" id="visitors" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder=""onChange={
                        (e) => {
                            setd8(e.target.value);
                        }
                    }/>
        </div>
        <div>
            <label for="visitors" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-start ml-2">Section (If any)</label>
            <input type="text" id="visitors" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder=""onChange={
                        (e) => {
                            setd12(e.target.value);
                        }
                    }/>
        </div>
    </div>
    <div class="mb-6">
        <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-start ml-2">Email address</label>
        <input type="email" id="email" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required onChange={
                        (e) => {
                            setd9(e.target.value);
                        }
                    }/>
    </div> 
    <div class="mb-6">
        <label for="password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-start ml-2">Aadhar No. Mother (Optional)</label>
        <input type="text" id="password" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder=""onChange={
                        (e) => {
                            setd10(e.target.value);
                        }
                    }/>
    </div> 
    <div class="mb-6">
        <label for="confirm_password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-start ml-2">Aadhar No. father (Optional)</label>
        <input type="text" id="confirm_password" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder=""  onChange={
                        (e) => {
                            setd11(e.target.value);
                        }
                    }/>
    </div> 
    <div className="m-3 ">
    <label for="confirm_password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Personal Photo (Only Face)</label>
            <input type="file"
                onChange={
                    (e) => {
                        if (e.target.files[0]) {
                            uploadimage(e.target.files[0])
                        }
                    }
                }/>
        </div>
        <div className="m-3 ">
    <label for="confirm_password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Adhar Photo (Front Side)</label>
            <input type="file"
                onChange={
                    (e) => {
                        if (e.target.files[0]) {
                            uploadimage1(e.target.files[0])
                        }
                    }
                }/>
        </div>
        <div className="m-3 ">
    <label for="confirm_password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Adhar Photo (Back Side)</label>
            <input type="file"
                onChange={
                    (e) => {
                        if (e.target.files[0]) {
                            uploadimage2(e.target.files[0])
                        }
                    }
                }/>
        </div>
    {/* <div class="flex items-start mb-6">
        <div class="flex items-center h-5">
        <input id="remember" type="checkbox" value="" class="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800" required />
        </div>
        <label for="remember" class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">I agree with the <a href="#" class="text-blue-600 hover:underline dark:text-blue-500">terms and conditions</a>.</label>
    </div> */}
    <button type="submit" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" onClick={(e)=>{
        handleSubmit(e)
    }}>Register</button>
</form>
</div>
    </div>
</div>
    );
  }
  
  export default Regster;