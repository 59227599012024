import React from "react";
import {useState} from "react";
import {Link, useNavigate} from 'react-router-dom'
import {signInWithEmailAndPassword} from "firebase/auth";
import ReCAPTCHA from 'react-google-recaptcha';
import {auth} from './firebase-config';
import './Home.css'
import Form from 'react-bootstrap/Form';

function Login() {
    // const [captchaValue, setCaptchaValue] = useState(null);
    const [LoginEmail, setaLoginEmail] = useState("");
    const [LoginPassword, setLoginPassword] = useState("");
    const [sk, setsk] = useState("");
    const nav = useNavigate();
    const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
     setValidated(true);
     event.preventDefault();
    //  if(captchaValue) {
    //      console.log('Captcha verified:', captchaValue);
    //      // Process form data
    //    } else {
    //     alert('Please verify the captcha');
    //      return;
    //    }
     signInWithEmailAndPassword(auth, LoginEmail, LoginPassword).then((userCredential) => { // Signed in
         const user = userCredential.user;
         alert("user signed in successfully")
         nav("/");
         // ...
     }).catch((error) => {
         const errorCode = error.code;
         setsk(errorCode);
     });
  };

    return (
        <div className="mt-5 mb-5">
             <Form noValidate
              validated={validated} 
             onSubmit={handleSubmit}
              className="mx-auto w-50 mt-5 bg-secondary   border border-light border border-3 border-opacity-10 rounded mb-32">
             <h5 className="text-center m-3 text-white">Log In</h5>
             <div className="m-3">
        <Form.Group  controlId="validationCustom01"
         onChange={
                          (e) => {
                              setaLoginEmail(e.target.value);
                          }
                      }
                      >
          <Form.Label className="text-white">Email</Form.Label>
          <Form.Control
            required
            type="email"
            placeholder=""
          />
          <Form.Control.Feedback></Form.Control.Feedback>
        </Form.Group>
        </div>
        <div className="m-3">
        <Form.Group controlId="validationCustom02"
          onChange={
                      (e) => {
                          setLoginPassword(e.target.value);
                      }
                  }
                  >
          <Form.Label className="text-white">Password</Form.Label>
          <Form.Control
            required
            type="text"
            placeholder=""
          />
          <Form.Control.Feedback></Form.Control.Feedback>
        </Form.Group>
        </div>
        <p className="m-3 text-warning">
          {sk} </p>
      <p className="m-3 text-white">If you don't have any account :
          <Link to="/register" className=" text-warning" style={{textDecoration:"none"}}> Register</Link>
      </p>
      {/* <p className="m-3 text-white g-recaptcha">
         <ReCAPTCHA className="g-recaptcha" sitekey="6LdbQP0kAAAAAOYiri-BoJLilRv1hA2GYoTjTy7n" hl='de' onChange={setCaptchaValue} />
      </p> */}
      <button type="submit" className="btn btn-dark m-3"
          onClick={handleSubmit}
          >Log in</button>
    </Form>
</div>
    );
  }
  
  export default Login;