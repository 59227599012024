import React from "react";
import {useState} from "react";
import {Link, useNavigate} from 'react-router-dom'
import {createUserWithEmailAndPassword} from "firebase/auth";
import {auth} from './firebase-config';
import {updateProfile} from "firebase/auth";
import {db} from './firebase-config'
import {setDoc, doc} from 'firebase/firestore'
import Form from 'react-bootstrap/Form';
import {ref, uploadBytes, getDownloadURL} from "firebase/storage"
import {storage} from "./firebase-config";

function Regster() {
    const [Email, setEmail] = useState("");
    const [Password, setPassword] = useState("");
    const [LoginName, setaLoginName] = useState("");
    const [sy, setsy] = useState("");
    const nav = useNavigate();
    const [validated, setValidated] = useState(false);
    const [url1, seturl1] = useState(""); 
    const [address, setaddress] = useState(""); 
    const [contactno, setcontactno] = useState(""); 

    
  const handleSubmit = (event) => {

    if( !LoginName,!Password,!Email,!url1,!contactno,!address){
       alert("please fill all details")
       return;
    }
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
     setValidated(true);
     event.preventDefault();
     createUserWithEmailAndPassword(auth, Email, Password).then((userCredential) => { // Signed in
         const user = userCredential.user;
         alert("user registered successfully")
         updateProfile(auth.currentUser, {
             displayName: LoginName
         }).then(() => {
         }).catch((error) => {});
         setDoc(doc(db, "User", user.uid), {
         uid:user.uid,
         name:LoginName,
         Password:Password,
         email:Email,
         type:"user",
         profileimage:url1,
         amount:"",
         Contact:contactno,
         address:address
         });
         nav("/");
     }).catch((error) => {
         const errorCode = error.code;
         setsy(errorCode);
     });
  };

    const uploadimage = async (event) => {
      const imageRef = ref(storage, LoginName);
      if (event) {
          uploadBytes(imageRef, event).then(() => {
              getDownloadURL(imageRef).then((url) => {
                  seturl1(url);
                  alert("Image Uploaded")
              }).catch((error) => {
                  console.log(error.message, "error geting the image url");
              })
          }).catch((error) => {
              console.log(error.message);
          })
      }
    };

    return (
        <div className="mt-5 mb-5">
            <Form noValidate validated={validated} onSubmit={handleSubmit}
              className="mx-auto w-50 mt-5 bg-secondary  mb-32   border border-dark border border-3 border-opacity-10 rounded">
            <h5 className="text-center m-3 text-white">Register</h5>
             <div className="m-3">
        <Form.Group  controlId="validationCustom01"
          onChange={
                        (e) => {
                            setaLoginName(e.target.value);
                        }
                    }
                    >
          <Form.Label className="text-white">Full Name (as in Aadhar/Identity Card)
</Form.Label>
          <Form.Control
            required
            type="text"
            placeholder=""
          />
          <Form.Control.Feedback></Form.Control.Feedback>
        </Form.Group>
        </div>
        <div className="m-3">
        <Form.Group controlId="validationCustom02"  
         onChange={
                            (e) => {
                                setEmail(e.target.value);
                            }
                        }
                        >
          <Form.Label className="text-white">Email</Form.Label>
          <Form.Control
            required
            type="email"
            placeholder=""
          />
          <Form.Control.Feedback></Form.Control.Feedback>
        </Form.Group>
        </div>

        <div className="m-3">
        <Form.Group controlId="validationCustom03"  
         onChange={
                            (e) => {
                                setaddress(e.target.value);
                            }
                        }
                        >
          <Form.Label className="text-white">Address</Form.Label>
          <Form.Control
            required
            type="email"
            placeholder=""
          />
          <Form.Control.Feedback></Form.Control.Feedback>
        </Form.Group>
        </div>

        <div className="m-3">
        <Form.Group controlId="validationCustom04"  
         onChange={
                            (e) => {
                                setcontactno(e.target.value);
                            }
                        }
                        >
          <Form.Label className="text-white">Contact No</Form.Label>
          <Form.Control
            required
            type="email"
            placeholder=""
          />
          <Form.Control.Feedback></Form.Control.Feedback>
        </Form.Group>
        </div>

        <div className="m-3 ">
            <input type="file"
                onChange={
                    (e) => {
                        if (e.target.files[0]) {
                            uploadimage(e.target.files[0])
                        }
                    }
                }/>
        </div>
        <div className="m-3">
        <Form.Group controlId="validationCustom03"   
         onChange={
                        (e) => {
                            setPassword(e.target.value);
                        }
                    }
                    >
          <Form.Label className="text-white">Password</Form.Label>
          <Form.Control
            required
            type="text"
            placeholder=""
          />
          <Form.Control.Feedback></Form.Control.Feedback>
        </Form.Group>
        </div>
        <p className="m-3 text-warning">
            {sy} </p>
        {/* <p className="m-3 text-white">Wenn Sie als Agent beitreten möchten :
            <Link to="/agent" className=" text-warning"> Agent</Link>
        </p> */}
        <p className="m-3 text-white">Already have an account : 
            <Link to="/login" className=" text-warning" style={{textDecoration:"none"}}> Log in</Link>
        </p>
        <button type="submit" className="btn btn-dark m-3"
            onClick={handleSubmit}
            >Register</button>
    </Form>
</div>
    );
  }
  
  export default Regster;