import Carousel from 'react-bootstrap/Carousel';
import {useEffect, useState} from "react";
import './Home.css';
import { collection } from "firebase/firestore";
import {getDocs} from "firebase/firestore";
import { db } from "./firebase-config";
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';


function Home() {
  const [slide3, setslide3] = useState([]);
  const [gallerydata, setgallerydata] = useState([]);
  const nav = useNavigate();

  useEffect(() => {
    fidata1();
    packagedata3();
}, []);


const packagedata3 = async () => {
  const colRef = collection(db, "Events");
  const snapshots = await getDocs(colRef);
  const docs = snapshots.docs.map(doc => doc.data());
      setgallerydata(docs);
}



  const fidata1 = async () => {
    const colRef = collection(db, "Slider");
    const snapshots = await getDocs(colRef);
    const docs = snapshots.docs.map(doc => doc.data());
        setslide3(docs);
  }

  

    return (
        <div>
            <div className='w-[80%] mx-auto '>
                <Carousel>
                {slide3.map(ele => (
      <Carousel.Item>
        <img
          className="d-block "
          src={ele.image}
          alt="Third slide"
          style={{"height":"725px"}}
        />

        <Carousel.Caption>
        <h3 className='fw-bold shadow-sm '>{ele.title}</h3>
          <p className='fw-bold shadow-sm '>{ele.subtitle}</p>
        </Carousel.Caption>
      </Carousel.Item>
     ))}
                </Carousel>
            </div>
             {/* ------------------------------------------- */}
            {/* <div className='bg-green-800'>
              <div className='p-5 '>
                <div className='container grid sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 pt-3 gap-12 w-[90%] justify-center' role="group">
                 <div className='flex flex-col '>
                   <h5 className='text-white my-1'>Donation</h5>
                   <a class="rounded-full text-white border-white border-1 my-1" style={{"textDecoration":"none"}} href='/donationreg'>Donate Now</a>
                 </div>
                 <div className='flex flex-col '>
                   <h5 className='text-white my-1'>Volunteer</h5>
                   <a class="rounded-full text-white border-white border-1 my-1" href='/memberreg' style={{"textDecoration":"none"}}>Join Now</a>
                 </div>
                 <div className='flex flex-col '>
                   <h5 className='text-white my-1'>Sponsorship</h5>
                   <a class="rounded-full text-white border-white border-1 my-1" href='/memberreg' style={{"textDecoration":"none"}}>Join Now</a>
                 </div>
            </div>
            </div>
            </div> */}
             {/* ------------------------------------------- */}
             {/* ------------------------------------------- */}
             <div className='m-5'>
   <h1 className='text-center fg'>Future Events</h1>
    <h3 className='text-center'>----------------o----------------</h3>
   <CardGroup className='m-2'>
   {gallerydata.filter((e)=>(new Date(e.date).getTime()>Date.now())).slice(0,3).map(packageele => ( 
                  <Card className='mx-3 '>
                  <Card.Img variant="top" className='in' src={packageele.images[0].url} />
                  <Card.Body>
                  <Card.Title className='text-start text-dark fw-bold cursor-pointer' onClick={
                                  (e) => {
                                    nav('/event', { state: { id:packageele.id,past:false} });
                                  }
                              } ><div dangerouslySetInnerHTML={{ __html:packageele.name}} /></Card.Title>
                              <div className=''>
                    <div className="mx-1 ">
                    <p className='text-danger text-start'>
                     </p>
                  </div>
                  
                    
                             </div>
                  </Card.Body>
                </Card>
                     ))}
    </CardGroup>
    <h1 className='text-center fg'>Past Events</h1>
    <h3 className='text-center'>----------------o----------------</h3>
    <CardGroup className='m-2'>
   {gallerydata.filter((e)=>(new Date(e.date).getTime()<=Date.now())).slice(0,3).map(packageele => ( 
                  <Card className='mx-3 '>
                  <Card.Img variant="top" className='in' src={packageele.images[0].url} />
                  <Card.Body>
                  <Card.Title className='text-start text-dark fw-bold cursor-pointer' onClick={
                                  (e) => {
                                    nav('/event', { state: { id:packageele.id,past:true} });
                                  }
                              } ><div dangerouslySetInnerHTML={{ __html:packageele.name}} /></Card.Title>
                              <div className=''>
                    <div className="mx-1 ">
                    <p className='text-danger text-start'>
                     </p>
                  </div>
                  
                    
                             </div>
                  </Card.Body>
                </Card>
                     ))}
    </CardGroup>
   </div>
           
            {/* ------------------------------------------- */}
        </div>
    );
}

export default Home;
